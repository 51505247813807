import { Injectable } from '@angular/core';

interface MenuItem {
  title: string;
  type: string;
  badge?: {
    class: string;
    text: string;
  };
  link?: string;
  active?: boolean;
  icon?: string;
  submenus?: MenuItem[];
}

@Injectable({
  providedIn: 'root'
})
export class SidebarMenuService {
  menus: MenuItem[] = [
    {
      title: 'navigation_menu',
      type: 'header'
    },
    {
      title: 'product_groups',
      type: 'simple',
      icon: '<i class="pe-7s-bicycle"> </i>',
      link: '/main/product-groups'
    },
    {
      title: 'merchant',
      type: 'simple',
      icon: '<img src="../../../assets/images/store_pic.png">',
      link: '/main/merchant-details'
    },
    {
      title: 'user_management',
      type: 'simple',
      icon: `<i class="pe-7s-users"> </i>`,
      link: '/main/user-management'
    },
    {
      title: 'mail_text',
      type: 'simple',
      icon: `<i class="pe-7s-mail"> </i>`,
      link: '/main/mail-text'
    },
    {
      title: 'markets',
      type: 'simple',
      icon: `<i class="pe-7s-culture"> </i>`,
      link: '/main/markets'
    },
    {
      title: 'api_update',
      type: 'simple',
      icon: `<i class="pe-7s-browser"> </i>`,
      link: '/main/api-update'
    },
    {
      title: 'menu_rate',
      type: 'simple',
      icon: '<i class="pe-7s-keypad"></i>',
      link: '/main/rate-list'
    },
    {
      title: 'george_store_products',
      type: 'simple',
      icon: `<i class="pe-7s-shopbag"> </i>`,
      link: '/main/george-store-product'
    },
    {
      title: 'activity_report',
      type: 'simple',
      icon: `<i class="pe-7s-note2"> </i>`,
      link: '/main/activity-report'
    },
  ];
  merchantMenu: MenuItem[] = [
    {
      title: 'store',
      type: 'simple',
      icon: `<i class="pe-7s-culture"> </i>`,
      link: '/main/store'
    },
    {
      title: 'product',
      type: 'simple',
      icon: `<i class="pe-7s-bicycle"> </i>`,
      link: '/main/product'
    },
    {
      title: 'leasing_calculator',
      type: 'simple',
      icon: `<i class="pe-7s-calculator"> </i>`,
      link: '/main/leasing-calculator'
    },
    {
      title: 'marketplace_approval',
      type: 'simple',
      icon: `<i class="pe-7s-portfolio"> </i>`,
      link: '/main/marketplace-approval'
    },
    {
      title: 'marketplace_foreign_products',
      type: 'simple',
      icon: `<i class="pe-7s-global"> </i>`,
      link: '/main/foreign-products'
    },
  ]

  constructor() { }



  getMenuList() {
    if (localStorage.getItem('role') == 'merchant' || localStorage.getItem('role') == 'merchant') {
      return this.merchantMenu;
    }
    return this.menus;
  }

  getMenuItemByUrl(aMenus: MenuItem[], aUrl: string): MenuItem {
    for (const theMenu of aMenus) {
      if (theMenu.link && theMenu.link === aUrl) {
        return theMenu;
      }

      if (theMenu.submenus && theMenu.submenus.length > 0) {
        const foundItem = this.getMenuItemByUrl(theMenu.submenus, aUrl);
        if (foundItem) {
          return foundItem;
        }
      }
    }

    return undefined;
  }

  toggleMenuItem(aMenus: MenuItem[], aCurrentMenu: MenuItem): MenuItem[] {
    return aMenus.map((aMenu: MenuItem) => {
      if (aMenu === aCurrentMenu) {
        aMenu.active = !aMenu.active;
      } else {
        aMenu.active = false;
      }

      if (aMenu.submenus && aMenu.submenus.length > 0) {
        aMenu.submenus = this.toggleMenuItem(aMenu.submenus, aCurrentMenu);
        if (aMenu.submenus.find(aChild => aChild.active)) {
          aMenu.active = true;
        }
      }

      return aMenu;
    });
  }
}
